import axios from 'axios';

export const createPayment = (property) =>
  axios.post(`${process.env.VUE_APP_API_URL}/payment`, {
    property,
  });

export const retrievePaymentInformation = (session_id) =>
  axios.post(`${process.env.VUE_APP_API_URL}/success`, {
    session_id,
  });
